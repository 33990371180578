<template>
  <v-dialog v-model="isOpen" max-width="400" persistent>
    <v-container class="grey lighten-5">
      <v-card-actions class="row-close">
        <v-spacer />
        <v-spacer />
        <v-btn icon color="gray" @click="close" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-row class="pt-5" justify="center" no-gutters>
        <v-col md="auto">
          <v-row class="pa-2">
            <v-img :alt="image.alt" :src="image.src" width="90px" />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" sm="8" class="offset-md-2 mt-n5">
          <v-list-item>
            <v-list-item-content>
              <div align="center" class="description-text">
                {{ text }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn
          class="white--text text-none button-cancel"
          :color="colorCancel"
          button
          @click="cancel"
        >
          {{ textCancel }}
          <v-icon class="icon-button" small>{{ iconCancel }}</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          class="white--text text-none button-confirm"
          :color="colorConfirm"
          button
          @click="confirm"
        >
          {{ textConfirm }}
          <v-icon class="icon-button" small>{{ iconConfirm }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionConfirmationDialog',

  data() {
    return {
      isOpen: false,
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      default: () => ({
        alt: 'warning',
        src: 'icons/ic-warning-green.svg',
      }),
    },
    text: {
      type: String,
      required: true,
      default: '',
    },
    textCancel: {
      type: String,
      required: true,
      default: '',
    },
    textConfirm: {
      type: String,
      required: true,
      default: '',
    },
    colorCancel: {
      type: String,
      default: 'gray',
    },
    colorConfirm: {
      type: String,
      default: 'green',
    },
    iconCancel: {
      type: String,
      default: 'mdi-close',
    },
    iconConfirm: {
      type: String,
      default: 'mdi-check',
    },
    cancel: {
      type: Function,
      default: () => {},
    },
    confirm: {
      type: Function,
      default: () => {},
    },
  },

  watch: {
    dialog(newDialog) {
      this.isOpen = newDialog
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) {
        this.close()
      }
    },
  },

  methods: {
    close() {
      this.isOpen = false
    },
  },
}
</script>

<style scoped>
::v-deep .v-list-item {
  padding: 0;
}
::v-deep .container {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.row-close {
  margin: -17px -12px;
}
.description-text {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.button-confirm {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
}
.button-cancel {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
}
.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}
</style>
