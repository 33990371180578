<template>
  <l-map style="height: 65vh; z-index: 1" :zoom="minZoom" :center="center">
    <l-control-layers position="topright" />

    <l-tile-layer
      name="Satellite"
      layer-type="base"
      :url="baseUrl"
      :subdomains="subdomains"
    ></l-tile-layer>

    <l-image-overlay
      name="NDVI"
      :url="imageWithPalette"
      :bounds="bounds"
    ></l-image-overlay>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LControlLayers, LImageOverlay } from 'vue2-leaflet'

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LImageOverlay,
  },
  props: {
    imageUrl: { required: true, type: String },
    latitude: { required: true, type: Number },
    longitude: { required: true, type: Number },
    bounds: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      minZoom: 14,
      baseUrl: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      center: [this.latitude, this.longitude],
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      imageWithPalette: '',
    }
  },
  watch: {
    imageUrl: {
      handler(newImgUrl) {
        this.imageWithPalette = `${newImgUrl}`
      },
      immediate: true,
    },
  },
}
</script>
